// 可自行拆分，这里懒得分模块了
export const lang = {
    //首页
    home: '홈페이지',
    opting: '옵션',
    market: '거래시장',
    mine: '나',
    whitePaper:"서류",
    selling:'판매 진행 중',
    coming:"예매 가 곧 시 작 됩 니 다",
    days:"일",
    houres:"시",
    minutes:"분",
    seconds:"초",
    PubilcSale:"청약하기",
    genesis:"창세 NFT 발행",
    genesisdesc:"ETH 를 연동한 고유한 NFT를 구매하고 캐스트하여, 많은 수익을 가져다 줄 새로운 여정이 시작된다",
    viewAll:'모두 보기',
    gameNavigation:'게임 내비게이션',
    refresh:"리프레시",
    firstNFT:"최초의 NFT 제품",
    cooperation:"협업 및 비협업 게임 가격 체계 및 가치 침전 모델 사용",
    Popular:"인기 있는 거래 시장",
    //上传
    UploadFile:"이미지 업로드",
    ChooseFile:"사진 선택",
    maxFile:"PNG，GIF，JPG 최대 20M",
    Title:"제목",
    TitlePlaceholder:"제목을 입력하십시오",
    Description:"설명",
    DescPlaceholder:"설명을 입력하십시오",
    mint:"업로드",
    //OPTING
    SortedBy:"정렬",
    value:"가치",
    numberOfpart:'참가자 수',
    Difference:"인원수 차이",
    follow:"팔로우",
    Countdown:"카운트다운",
    //MARKET
    results:'개 결과',
    price:'판매 가격',
    Time:"시간.",
    highTolow:"내림순",
    lowTohigh:"오름순",
    // detail
    Recent:'최신',
    SalesHistory:"거래 내역",
    HistoryOfParticipation:"역대 참여자 수",
    publish:"게임 퍼블리싱",
    sell:"세일",
    //购买弹窗
    saleDetails:"판매 내역",
    flxedPrice:"고정 가격",
    soldPrice:"고정 가격에 팔릴 것입니다",
    postListing:"상품 업로드",
    //取消挂单
    cancel:'주문 취소',
    cancelDesc:"주문이 취소되면 거래 시장 목록에 표시되지 않음",
    think:"생각해보기",
    cacelListing:"상품 매진",
    sellPrice:"판매 가격",
    endTime:"종료 시간",
    gameState:"게임 상태",
    ticketPrice:"입장권",
    Allocation:"상여금 분배",
    settle:"결제",
    waiting:"결제 대기 중",
    Settled:"정착",
    Join:'즉시 참여',
    chooseCamp:"당신 의 진영 을 선택 하 세 요",
    Confirm:"확인 하 다.",
    earnings:"획득",
    info:'무승부 시 참가자는 입장권 가격의 5%를 공제받는다.',
    //mine
    account:"내 계정",
    Portfolio:"포트폴리오",
    Participating:"사용 중인 자금",
    Available:"가용 자금",
    withdraw:"현금 인출",
    myNFT:"내 NFT",
    participation:"참여 역사",
    Purchase:"구매",
    buy:"사다",
    buyInfo:"",
    People:"인원수",
    AddCollect:"모음 집 추가",
    CancelCollect:"모음 집 취소",
    nftLevelUp:"NFT 침전",
    fee:"수수료(5%)",
    recieved:"수령",
    transactionFailed:"거래 실패",
    failedDesc:"사용자가 거래 서명을 취소했음",
    minting:"캐스트 중",
    successTrasaction:"거래 성공 후 '내 NFT' 페이지에서 확인",
    etherscan:"이더스캔에서 보기",
    confirmation:"확인 대기 중",
    or:"혹은",
    votingFailed:"투표 실패",
    votingSuccess:"선택 완료",
    votingSuccessLater:"나중에 페이지 새로 고침",
    failed:"실패",
    //sale
    PurchaseType:"청 약",
    endingTime:"종료 시간",
    token:"토큰",
    tokensale:"총 공급량",
    Contract:"계약 주소",
    Personal:"개인 상세내역",
    TotalBalance:"구매 수량",
    Locked:"잠금 수량",
    Claim:"교환",
    round:"",
    roundDesc:"라운드",
    Fundraise:"모금액",
    Amount:"토큰 공급량",
    Price:"변환 비율",
    LockUp:"잠금 상태",
    lockUpDesc:"프로젝트 오픈에 0%, 이후 70일마다 1회 릴리즈(첫 번째 70일에 20% 릴리즈, 두 번째 70일에 30% 릴리즈, 세 번째 70일에 50% 릴리즈)",
    receive:"를 받게 됩니다.",
    Balance:"총액",
    Buy:"청약",
    distribution:"공모 상세 정보",
    Tokens:"공급량",
    Prices:"변환 비율",
    Supply:"비율",
    Raise:"모금액",
    detailed:"토큰 이코노미 상세 정보",
    TotalSupply:"총 공급량",
    desc1:"레볼루션(rtv)은 erc20 표준을 준수하는 토큰으로, 이더리움처럼 탈중심화 거래소와 암호화폐 거래소에서 거래할 수 있다",
    desc2:"이것은 플레이어와 투자자에게 있어서 플랫폼의 통행증일 뿐만 아니라 독립적이고 가치가 있는 화폐이기도 하다",
    Activities:"거래 기록",
    Date:"날짜",
    prices:"변환 비율",
    Priced:"변환 비율",
    //饼图
    foundation:"재단",
    maketing:"시장 운영",
    team:"팀",
    publicSale:"공모전",
    yieldFarming:"채굴",
    please:"잠시 만 기 다 려 주세요.",
    MateMask:"계 정 을 연결 할 수 있 도록 MateMask 를 먼저 설치 하 십시오.",
    withdrawSuccess:"현금 인출 에 성공 하 다.",
    copy:"클립보드 로 복사 됨",
    copyError:"복사 실패",
    PurchaseSuccess:'구 매 성공',
    fileTypeError:"업로드 한 파일 형식 이 잘못 되 었 습 니 다",
    allocationInfoLine1:'무승부 시 참가자는 입장권 가격의 5%를 공제받는다.',
    allocationInfoLine2:'',
    WhatweDo:'우리는 무엇을 하는가',
    realized:'가치를 실현하고, 유통을 간단하게 합니다',
    //2022-09-09 任务奖励
    taskDetail:'과제 구체 정보',
    reward:'인터랙티브 보상',
    oneTime:'일회적',
    completed:'모든 과제(민트/발행/매출)가 완료되면 인터랙티브 보상을 받을 수 있습니다.',
    Reward:'보상',
    dailyReward:'일일 보상',
    NumberVotes:'투표 수',
    Daily:'일일',
    taskReset:'30회 투표할 때마다 RTV 토큰 1개씩, 일당 최대 3개의 RTV 토큰 보상을 받을 수 있습니다. 과제는 매일 초기화됩니다!',
    votes200:'받은 투표 수가 200개 달성하였습니다',
    Eligible:'적격',
    addresses:'개 주소',
    closeTask500:"테스트 중 적격 주소의 수가 500개에 도달하면  과제를 종료시킬 것입니다.",
    RankingReward:'랭킹 보상',
    vote:'투표수',
    ongoing:'진행 중',
    end:'종료',
    voteObtained:'득표수',
    award:'최종 보상은 최종 순위에 따라 결정됩니다.',
    bug:'버그/건의 보상',
    contactUs:'연락해 주세요',
    // airdrop
    Airdrop:'공중 투하',
    Tasks:'과제',
    Ranking:'랭킹',
    ClaimRTV:'RTV 토큰 교환',
    ConnectWallet:'지갑 연결',
    Faucet:'수도꼭지',
    Whitepaper:'백서',
    create:'만들기',
    introduced:"게임 형식을 도입하고 NFT가 보다 충분한 게임을 구현하도록 하여 NFT의 가치발견을 실현합니다.",
    auctionModel:'고유의 단일 경매 형식과 비교해 보면,  협력 게임 및 비협력 게임 모델을 도입한 우리의 형식은  NFT가 보다 충분한 게임을 보유하고 그 가격이 NFT 자체의 가치와 가깝게 하여  NFT의 가치 발견을 실현할 수 있습니다',
    Towinnners:'우승까지',
    ToNFTLevelUp:'NFT 레벨업까지',
    VotesObtained:'득표수',
    rankingNumber:'순위',
    quantity:'수량',
    Address:'주소',
    AttentionPlease:'주의사항',
    AttentionText:'테스트 기간에 거래 또는 투표로 획득한 모든 토큰(ETH 및 RTV포함)은 전부 테스트용 토큰입니다! 과제 완료로 획득한 RTV 토큰만 Ehereum Mainnet에서 교환 가능합니다!',
    AttentionText2:'',
    TotalVoting:'총투표',
    TotalBoundValue:"바인딩 총액",
    TotalSalesValue:"총 매출",
    TotalEarnings:"총 수익",
    OpenPositions:'포지션 개설',
    Cash:"현금",
    Withdraw:"추출",
    InteractiveReward:"인터랙티브 보상",
    DailyReward:'일일 보상',
    bugReward:"버그/건의 보상",
    RankingReward:"랭킹 보상",
    viewRanking:"랭킹 조회",
    pictureRule:"플랫폼의 이미지 순위 규정을 읽었으며 이에 동의합니다.",
    CompleteYourListing:"리스트를 완성하세요",
    ApproveNFT:"NFT 승인",
    ConfirmListing:'리스트 확인',
    AllOption:"필터",
    Active:"활동적인",
    Closed:"닫은",
    EndSoon:"끝이 난다",
    Liked:"처럼",
    Volume:"표수",
    HighToLow:"높음부터 낮음까지",
    LowToHigh:"낮음부터 높음까지",
    BoundValue:"바인딩 값",
    DifferenceVotes:"표수 변화",
    Recently:"요즈음",
    Boundvalue:"바인딩 값",
    WaitingSettlement:"정산 대기",
    Finished:"끝내다",
    Filter:"여과",
    uploadSuccess:"업로드 성공",
    immediately:'심사가 통과되면 즉시 내 NFT에서 주조할 수 있습니다',
    Mint:"주조",
    Filter:"출금",
    COMPLETE:"결제 완료",
    ConfirmCheckOut:"결제 확인",

    username: '계좌',
    email: '이메일',
    verifyCode: '캡차',
    sendVerifyCode:'인증 코드 보내기',
    password: '암호',
    confirmPassword: '비밀번호 확인',
    createAccount:'계정 만들기',
    usernamePlaceholder: '계정 이름을 입력해 주세요',
    emailPlaceholder: '이메일 주소를 입력해 주세요',
    verifyCodePlaceholder: '인증 코드를 입력해 주세요',
    passwordPlaceholder: '비밀번호를 입력해 주세요',
    confirmPasswordPlaceholder: '비밀번호를 다시 입력해 주세요',
    usernameRule: '계정은 비워 둘 수 없습니다',

    //需要翻译的
    Dashboard:'대시보드',
    estimated:'예상 잔액',
    wallet:'지갑 개요',
    funding:'자금 조달 개요',
    deposit:'입금',
    withdraw:'출금',
    crypto:'암호화폐 입금',
    coin:'코인 선택',
    network:'네트워크 선택',
    address:'입금 주소',
    pleaseCoin:'코인을 선택해 주세요',
    pleaseNetwork:'네트워크를 선택해 주세요',
    textNetwork:'네트워크',
    cancel:'취소',
    recharged:'재충전됨',
    submit:'제출',
    minimum:'최소 입금액',
    replicationSuccessful:'복제가 성공했습니다',
    replicationFailed:'복제가 실패했습니다',
    received:'수신됨',
    notRreceived:'수신되지 않음',
    withdrawCrypto:'암호화폐 출금',
    SendAddress:'주소로 보내기',
    Amount:'금액',
    Balance:'잔액',
    minimunWithdrawal:'최소 출금액',
    All:'전체',
    premium:'프리미엄',
    rate:'요율',
    textRevolution:'플랫폼에서 지원하는 네트워크만 표시됩니다. 다른 네트워크를 통해 입금하는 경우 자산이 손실될 수 있습니다.',
    Security:'보안',
    Authentication:'이중 인증',
    Email:'이메일',
    account:'이메일을 사용하여 계정과 거래를 보호하세요.',
    LoginPassword:'로그인 비밀번호',
    textPassword:'로그인 비밀번호는 계정에 로그인하는 데 사용됩니다.',
    Modify:'수정',
    textVerifyCode:'인증 코드를 입력해 주세요',
    textEmailAddress:'새 이메일 주소를 입력해 주세요',
    loginModify:'로그인 비밀번호 변경',
    ForgotPassword:'비밀번호를 잊으셨나요?',
    oldPassword:'이전 비밀번호를 입력해 주세요',
    newPassword:'새 비밀번호를 입력해 주세요',
    againPassword:'새 비밀번호를 다시 입력해 주세요',
    PasswordText:'새 비밀번호가 일치하지 않습니다',
    PasswordModify:'비밀번호 변경',
    VerifyCode:'인증 코드',
    NewEmail:'새 이메일',
    OldPassword:'이전 비밀번호',
    NewPassword:'새 비밀번호',
    ConfirmNewPassword:'새 비밀번호 확인',
    Fund:'기금',
    alls:'전체',
    available:'사용 가능',
    Frozen:'동결됨',
    other:'기타',
    Transaction:'거래',
    Identification:'식별',
    Payment:'결제',
    logout:'로그아웃',
    Login:'로그인',
    Welcome:'RevoNFT에 오신 것을 환영합니다.',
    Register:'회원으로 등록하여 경험하세요.',
    EmailName:'이메일/계정 이름',
    PleaseEmailName:'이메일/계정 이름을 입력해 주세요',
    SignUp:'가입하기',
    NeedAccount:'법인 계정이 필요하십니까?',
    AlreadyAccount:'이미 계정이 있으십니까?',
    verification:'이메일로 인증 코드가 발송되었습니다',
    receive:'인증 코드를 받지 못하셨나요?',
    Resend:'재발송',
    Resend:'재발송',
    addressEmpty:'주소를 비워 둘 수 없습니다',
    withdrawalEmpty:'출금액을 비워 둘 수 없습니다',
    platformEmpty:'플랫폼 ID를 비워 둘 수 없습니다',
    transactionType:'거래 유형',
    Payouts:'지급',
    Into:'입금',
    RollOut:'출금',
    TransactionID:'거래 ID',
    PersonalCenter:'프로필',
    ReturnPage:'돌아가기',
    currency:'통화',
    EditProfilePhoto:'프로필 사진 편집',
    Profile:'프로필',
    Referral:'추천 코드',
    ViewInvitationCode:'초대 코드 보기',
    referral:'추천 코드를 입력해 주세요',
    QRCodeInvitations:'QR 코드 초대',
    ScanTheQRCode:'QR 코드 스캔',
    invitations:'링크 초대',
    invitedUsers:'초대 링크를 복사하여 초대받은 사용자에게 전송하세요',
    registerNumber:'등록을 위해 초대받은 사람들의 수',
    PersonalInformation:'개인 정보',
    LegalName:'법적 이름',
    PleaseLegalName:'법적 이름을 입력해 주세요',
    DateOfBirth:'생년월일',
    PleaseDateOfBirth:'생년월일을 입력해 주세요',
    ideAddress:'주소',
    PleaseIdeAddress:'주소를 입력해 주세요',
    IdentificationNumber:'식별 번호',
    PleaseIdentificationNumber:'식별 번호를 입력해 주세요',
    IdentificationDocuments:'신분증',
    frontIDCard:'신분증 앞면',
    frontIDCardText:'신분증 앞면을 업로드해 주세요',
    ReverseIDCard:'신분증 뒷면',
    ReverseIDCardText:'신분증 뒷면을 업로드해 주세요',
    EmailAddress:'이메일 주소',
    Manage:'관리',
    ResetPassword:'비밀번호 재설정',
    Unauthorized:'무단',
    authorized:'승인됨',
    confirmText:'확인',
    cancelText	:'취소',
    authentication	:'실명 인증',
    UnderReview	:'검토 중',
    Failed	:'실패함',
    PasswordVerification:'비밀번호 검증',
    PleasePassword:'비밀번호를 입력해 주세요',
    typeTransaction:'형',
    amountTransaction:'분량',
    Authentication:'2단계 인증',
    enable:'이 기능을 활성화하시겠습니까?',
    OFF:'닫다',
    ON:'열다',
    Enable:'사용',
    RemoveMyNFT:'"My NFT"에서 삭제합니다.',
    AreYouSure:'확실한가요',
    ToRemove:'제거하려면?',
    feedback:'사용자 피드백',
    currencyType:'통화 유형',
    amount:'분량',
    Time:'시간',
    ReviewStatus:'검토 상태',
    ReasonReview:'검토 이유',
    WithdrawalHistory:'출금 내역',
    announcement:'발표',
    Ongoing:'지속적인',
    Ended:'끝난',
    NumberParticipants:'참가 인원',
    EndTime:'종료 시간',
    totalAmount:'총액',
    GameID:'게임 ID',
    gasDeduction:'가스 요금은 실제 공제 대상입니다.',
    GameTitle:'게임 타이틀',
    feedbackType:'피드백의 유형',
    FeedbackContent:'피드백 내용',
    LockUp:'락업(Lock-up)',
    Destroyed:'파괴',
    arrived:'도착했는지 여부',
    ReviewTime:'검토 시간',
    ProcessingStatus:'처리 상태',
    ReasonRejection:'거부 사유',
    Delete:'삭제하다',
    SignUp:'회원가입 및 로그인',
    Sale:'판매',
    Game:'게임',
    bill:'계산서',
    Deposits:'입출금',
    CreationTime:'생성 시간',
    Read:'읽다',
    Unread:'읽지 않은',
    teamwork:'팀워크',
    Referral:'추천 보상',
    community:'커뮤니티 보상',
    SuperNode:'슈퍼 노드 보상',
    downlines:'총 인원',
    Completed:'완료된 세션',
    Sessions:'세션을 완료해야 합니다.',
    source:'근원',
    Expected:'기대되는 혜택',
    install:'Revo 설치',
    TotalRevenue:'총 매출',
    contributors:'기여자 수',
    settled:'정산 여부',
    sed:'정착',
    unSed:'정산되지 않음',
}