// 可自行拆分，这里懒得分模块了 中文繁体
export const lang = {
    //首页
    home: '主頁',
    opting: '遊戲',
    market: '交易市場',
    mine: '我的',
    whitePaper: "檔案",
    selling: '售賣進行中',
    coming: "售賣即將開始",
    days: "天",
    houres: "時",
    minutes: "分",
    seconds: "秒",
    PubilcSale: "去認購",
    genesis: "創世NFT發行",
    genesisdesc: "購買並鑄造一個綁定了ETH的獨一無二的NFT，開始一段新的征程，他可能會為你帶來豐厚的收益",
    viewAll: '查看全部',
    gameNavigation: "遊戲大航海",
    refresh: "換一組",
    firstNFT: "第一個NFT產品",
    cooperation: "使用合作和非合作博弈定價系統和價值沉澱模型",
    Popular: "受歡迎的交易市場",
    //上传
    UploadFile: "上傳圖片",
    ChooseFile: "選擇照片",
    maxFile: "PNG、GIF、JPG 最大20M",
    Title: "標題",
    TitlePlaceholder: "請輸入標題",
    Description: "描述",
    DescPlaceholder: "請輸入描述",
    mint: "上傳",
    //OPTING
    SortedBy: "排序",
    value: "價值",
    numberOfpart: '參與人數',
    Difference: "人數差",
    follow: "關注",
    Countdown: "即將截止",
    //MARKET
    results: '個結果',
    price: '售價',
    Time: "時間",
    highTolow: "從高到低",
    lowTohigh: "從低到高",
    // detail
    Recent: '近期',
    SalesHistory: "交易歷史",
    HistoryOfParticipation: "歷史參與人數",
    publish: "發佈遊戲",
    sell: "出售",
    //购买弹窗
    saleDetails: "銷售詳情",
    flxedPrice: "固定價格",
    soldPrice: "它將以固定價格賣出",
    postListing: "出售",
    //取消挂单
    cancel: '取消掛單',
    cancelDesc: "取消掛單後將不再展示在交易市場清單中",
    think: "讓我想想",
    cacelListing: "取消掛單",
    sellPrice: "售價",
    endTime: "結束時間",
    gameState: "遊戲狀態",
    ticketPrice: "門票",
    Allocation: "獎金分配",
    settle: "結算",
    waiting: "等待結算",
    Settled: "已結算",
    Join: "立即參與",
    chooseCamp: "選擇你的陣營",
    Confirm: "確認",
    earnings: "你獲得",
    info: '如果平局，參與者將被扣除門票價格的5%',
    //mine
    account: "我的帳戶",
    Portfolio: "資產組合",
    Participating: "使用中的資金",
    Available: "可用資金",
    withdraw: "提現",
    Withdrawal: "提現",
    myNFT: "我的NFT",
    participation: "參與歷史",
    Purchase: "購買",
    buy: "以",
    buyInfo: "購入",
    People: "人數",
    AddCollect: "添加收藏",
    CancelCollect: "取消收藏",
    nftLevelUp: "NFT沉澱",
    fee: "手續費 (5%)",
    recieved: "您將收到",
    transactionFailed: "交易失敗",
    failedDesc: "用戶取消了交易簽名",
    minting: "鑄造中",
    successTrasaction: "交易成功後在‘我的NFT’頁面查看",
    etherscan: "在以太坊瀏覽器上查看",
    confirmation: "等待確認",
    or: "或",
    votingFailed: "投票失敗",
    votingSuccess: "選擇成功",
    votingSuccessLater: "稍後刷新頁面",
    failed: "失敗",
    //sale
    PurchaseType: "認購",
    endingTime: "截止時間",
    token: "代幣",
    tokensale: "總供應量",
    Contract: "合約地址",
    Personal: "個人明細",
    TotalBalance: "購買數量",
    Locked: "鎖倉數量",
    Claim: "提現",
    round: "第",
    roundDesc: "輪",
    Fundraise: "募集金額",
    Amount: "供應量",
    Price: "兌換比例",
    LockUp: "鎖倉情况",
    lockUpDesc: "項目上線釋放0%，之後每70天釋放一次（第一個70天釋放20%，第二個70天釋放30%，第三個70天釋放50%",
    receive: "你將收到",
    Balance: "餘額",
    Buy: "認購",
    distribution: "公募輪詳情",
    Tokens: "供應量",
    Prices: "兌換比例",
    Supply: "占比",
    Raise: "募集金額",
    detailed: "代幣經濟詳情",
    TotalSupply: "總供應量",
    desc1: "Revolution（rtv）是一種符合erc20標準的代幣，可以像乙太坊一樣在去中心化交易所和中心化交易所上交易",
    desc2: "對於玩家和投資者來說，它不僅是平臺的通證，也是一種獨立的且具有價值的貨幣",
    Activities: "交易記錄",
    Date: "日期",
    Description: "類型",
    prices: "價格",
    Priced: "售價",
    foundation: "基金會",
    maketing: "市場運營",
    team: "團隊",
    publicSale: "公募輪",
    yieldFarming: "挖礦",
    please: "請稍等",
    MateMask: "請先安裝MateMask，以便綁定帳戶",
    withdrawSuccess: "提現成功",
    copy: "已複製到剪貼板",
    copyError: "複製失敗",
    PurchaseSuccess: '複製成功',
    fileTypeError: "上傳的檔案類型有誤",
    allocationInfoLine1: '如果平局，參與者將被扣除門票價格的5%,即95%的回報率',
    allocationInfoLine2: '',
    WhatweDo: "我們的願景",
    realized: '讓價值得以發現，讓流通變得簡單',
    //2022-09-09 任务奖励
    taskDetail: 'Task detail',
    reward: 'Interactive reward',
    oneTime: 'One-time',
    completed: 'You will get interactive reward when tasks（mint/publish/sell）are all completed.',
    Reward: 'Reward',
    dailyReward: 'Daily reward',
    NumberVotes: 'number of votes',
    Daily: 'Daily',
    taskReset: '1 RTV per 30-votes ，no more than 3 RTV everyday.The mission will be reset daily！',
    votes200: 'Vote-obtained reach 200',
    Eligible: 'Eligible',
    addresses: 'addresses',
    closeTask500: "During the test , When the number of eligible address reach 500 , we'll close the mission",
    RankingReward: 'Ranking Reward',
    vote: 'vote',
    ongoing: 'ongoing',
    end: 'end',
    voteObtained: 'vote-obtained',
    award: 'The final award shall be subject to the final ranking',
    bug: 'BUG/Suggestions reward',
    contactUs: 'contact us',
    // airdrop
    Airdrop: '空投',
    Tasks: '任務',
    Ranking: '排名',
    ClaimRTV: '索賠$RTV',
    ConnectWallet: 'Connect wallet',
    Faucet: '水龍頭',
    Whitepaper: '白皮書',
    create: 'Create',
    introduced: "引入博弈形式，使NFT經歷更充分的博弈，從而實現NFT的價值發現。",
    auctionModel: '與傳統的單一拍賣模式相比，引入合作博弈和非合作博弈模式，使NFT具有更充分的博弈，使價格更接近NFT本身的價值，從而實現NFT的價值發現。',
    Towinnners: 'To winnners',
    ToNFTLevelUp: 'To NFT Level-Up',
    VotesObtained: 'Votes obtained',
    rankingNumber: 'Ranking',
    quantity: 'Quantity',
    Address: 'Address',
    AttentionPlease: 'Attention please',
    AttentionText: 'During the test, all tokens you earned (ETH&RTV) by trading or voting are testcoins on the test network !',
    AttentionText2: 'Only the RTV by completing the task can be claimed on Ethereum Mainnet !',
    TotalVoting: 'Total Voting',
    TotalBoundValue: "Total Bound Value",
    TotalSalesValue: "Total Sales Value",
    TotalEarnings: "Total Earnings",
    OpenPositions: 'Open Positions',
    Cash: "Cash",
    Withdraw: "Withdraw",
    InteractiveReward: "Interactive reward",
    DailyReward: 'Daily reward',
    bugReward: "BUG/Suggestions reward",
    RankingReward: "Ranking reward",
    viewRanking: "View ranking",
    pictureRule: "I have already read and agree the picture ranking rules of the platform",
    CompleteYourListing: "Complete your listing",
    ApproveNFT: "Approve NFT",
    ConfirmListing: 'Confirm listing',
    AllOption:"全部",
    Active:"積極",
    Closed:"關閉",
    EndSoon:"即將結束",
    Liked:"喜歡",
    Volume:"票數",
    HighToLow:"從高到低",
    LowToHigh:"從低到高",
    BoundValue:"邊界值",
    DifferenceVotes:"票數差異",
    Recently:"最近上市",
    Boundvalue:"Bound-Value",
    WaitingSettlement:"等待結算",
    Finished:"完成",
    Filter:"過濾",
    //
    username: '賬戶',
    email: '電子郵箱',
    verifyCode: '驗證碼',
    sendVerifyCode:'發送驗證碼',
    password: '密碼',
    confirmPassword: '確認密碼',
    createAccount:'創建賬戶',
    usernamePlaceholder: '請輸入您的賬戶名',
    emailPlaceholder: '請輸入您的電子郵箱地址',
    verifyCodePlaceholder: '請輸入您的驗證碼',
    passwordPlaceholder: '請輸入您的密碼',
    confirmPasswordPlaceholder: '請再次輸入您的密碼',
    usernameRule: '賬戶不能為空',
    //
    Dashboard: '總覽',
    estimated:'資產總覽',
    wallet:'淨資產趨勢',
    funding:'資產概覽',
    deposit:'充值',
    withdraw:'提現',
    crypto:'充值貨幣',
    coin:'選擇貨幣',
    network:'選擇網路',
    address:'充值位址',
    pleaseCoin:'請選擇貨幣',
    pleaseNetwork:'請選擇網路',
    textNetwork:'主網',
    cancel:'取消',
    recharged:'確認到賬',
    submit:'提交',
    minimum:'最小充值數',
    replicationSuccessful:'複製成功',
    replicationFailed:'複製失敗',
    received:'已到賬',
    notRreceived:'未到賬',
    withdrawCrypto:'提現',
    SendAddress:'位址',
    Amount:'提現金額',
    Balance:'可用',
    minimunWithdrawal:'最少提現',
    All:'全部提現',
    premium:'手續費', 
    rate:'手續費率',
    amountReceived:'到賬金額',
    gas:'gas費',
    textRevolution:'請注意，僅顯示 Revolution 平臺上支持的網路。如果您通過其他網路存款，您的資產可能會丟失。',
    Security:'安全',
    Authentication:'雙因素身份驗證',
    Email:'郵箱',
    account:'使用您的電子郵件來保護您的帳戶和交易。',
    LoginPassword:'登錄密碼',
    textPassword:'登錄密碼用於登錄您的帳戶。',
    Modify:'郵箱修改',
    textVerifyCode:'請輸入驗證碼',
    textEmailAddress:'請輸入電子郵件位址',
    loginModify:'登錄密碼修改',
    ForgotPassword:'忘記密碼',
    oldPassword:'請輸入舊密碼',
    newPassword:'請輸入新密碼',
    againPassword:'請重新輸入新密碼',
    PasswordText:'新密碼與再次新密碼不相符',
    PasswordModify:'密碼修改',
    VerifyCode:'驗證碼',
    NewEmail:'新電子郵件',
    OldPassword:'舊密碼',
    NewPassword:'新密碼',
    ConfirmNewPassword:'確認新密碼',
    Fund:'資金帳戶',
    alls:'全部',
    available:'可用',
    Frozen:'已凍結',
    other:'其他',
    Transaction:'帳戶流水',
    Identification:'認證',
    Payment:'充值',
    logout:'退出',
    Login:'登錄',
    Welcome:'歡迎來到 RevoNFT。',
    Register:'註冊成為會員即可體驗。',
    EmailName:'電子郵件/帳戶名稱',
    PleaseEmailName:'請輸入您的電子郵件/帳戶名稱',
    SignUp:'註冊',
    NeedAccount:'需要實體帳戶？',
    AlreadyAccount:'已經有帳戶了？',
    verification:'驗證碼已發送到電子郵件',
    receive:'沒有收到驗證碼？',
    Resend:'發送',
    addressEmpty:'位址不能為空',
    withdrawalEmpty:'提現金額不能為空',
    platformEmpty:'平臺ID不能為空',
    transactionType:'交易類型',
    Payouts:'提現',
    Into:'轉入',
    RollOut:'轉出',
    TransactionID:'交易',
    PersonalCenter:'個人中心',
    ReturnPage:'返回',
    currency:'貨幣',
    EditProfilePhoto:'編輯個人照片',
    Profile:'個人資料',
    Referral:'推薦碼',
    ViewInvitationCode:'查看邀請碼',
    referral:'請輸入推薦碼',
    QRCodeInvitations:'二維碼邀請',
    ScanTheQRCode:'掃碼二維碼',
    invitations:'連結邀請',
    invitedUsers:'拷貝邀請連結，發送給被邀請使用者',
    registerNumber:'已邀請註冊人數',
    PersonalInformation:'個人資訊',
    LegalName:'姓名',
    PleaseLegalName:'請輸入姓名',
    DateOfBirth:'出生日期',
    PleaseDateOfBirth:'請選擇出生日期',
    ideAddress:'位址',
    PleaseIdeAddress:'請輸入位址',
    IdentificationNumber:'身份證號',
    PleaseIdentificationNumber:'請輸入身份證號',
    IdentificationDocuments:'身份證件',
    frontIDCard:'身份證正面',
    frontIDCardText:'請上傳身份證正面',
    ReverseIDCard:'身份證反面',
    ReverseIDCardText:'請上傳身份證反面',
    EmailAddress:'電子郵件位址',
    Manage:'管理',
    ResetPassword:'重置密碼',
    Unauthorized:'未經授權',
    authorized:'已授權',
    confirmText:'確認',
    cancelText	:'取消',
    authentication	:'實名認證',
    UnderReview	:'審核中',
    Failed	:'失敗',
    PasswordVerification:'密碼驗證',
    PleasePassword:'請輸入密碼',
    typeTransaction:'類型',
    amountTransaction:'金額',
    Authentication:'雙因素身份驗證',
    enable:'您確定啟用此功能?',
    OFF:'關',
    ON:'開',
    Enable:'啟用',
    RemoveMyNFT:'從「我的 NFT」中刪除',
    AreYouSure:'是否確定',
    ToRemove:'要刪除？',
    feedback:'用戶反饋',
    currencyType:'貨幣類型',
    amount:'金額',
    Time:'時間',
    ReviewStatus:'審核狀態',
    ReasonReview:'審核原因', 
    WithdrawalHistory:'提現記錄',
    announcement:'公告',
    Ongoing:'進行中',
    Ended:'已結束',
    NumberParticipants:'參與人數',
    EndTime:'結束時間',
    totalAmount:'總金額',
    GameID:'遊戲ID',
    GameTitle:'遊戲標題',
    gasDeduction:'gas費以實際扣款為準',
    feedbackType:'反饋類型',
    FeedbackContent:'反饋內容',
    LockUp:'鎖倉',
    Destroyed:'銷毀',
    arrived:'是否到賬',
    ReviewTime:'審核時間',
    ProcessingStatus:'處理狀態',
    ReasonRejection:'拒絕原因',
    Delete:'刪除',
    SignUp:'註冊登錄',
    Sale:'售賣',
    Game:'遊戲',
    bill:'帳單',
    Deposits:'充提',
    CreationTime:'創建時間',
    Read:'已讀',
    Unread:'未讀',
    teamwork:'團隊合作',
    Referral:'推薦獎勵',
    community:'社區獎勵',
    SuperNode:'超級節點獎勵',
    downlines:'總人數',
    Completed:'已完成場次',
    Sessions:'需要完成場次',
    source:'來源',
    Expected:'預期收益',
    install:'安裝 Revo',
    TotalRevenue:'團隊收益',
    contributors:'貢獻人數',
    settled:'是否已結算',
    sed:'已結算',
    unSed:'未結算',
}