//后端接口请求地址
// const BASE_V_API = 'https://www.revonft.com/api'
const BASE_V_API = '/api'
//后端合约请求地址
const ContractUrl = 'https://www.revonft.com/chain/'
// rtvtoken 地址
const rtvtokenAddress = "0xf07FcEeCab2339AbE8322fe40C1331bda1ac9E5f"
// Rtv721 地址
const Rtv721Address = "0xDCcfb29523B9eb243DE5F1ad445d5B49F48DB46A"
// market 地址
const marketAddress = "0xEb472015a30C1F340850B12dbE4c7BF84fAf0C90"
//水龙头地址
const FaucetAddress='https://www.revonft.com/faucet/'

/*
//for testnet
//后端接口请求地址
const BASE_V_API = 'http://192.168.3.6/api'
//后端合约请求地址
const ContractUrl = 'http://192.168.3.6/chain/'
// rtvtoken 地址
const rtvtokenAddress = "0x27DA8E065c44EE65f49e17410e0b250614341B1b"
// Rtv721 地址
const Rtv721Address = "0xE007bcE39Cd523DDB84fE846fd4610ebF4cD0705"
// market 地址
const marketAddress = "0x63DFFFadfD62eE32F220d2Fcab7319a223A4dD34"
//水龙头地址
const FaucetAddress='http://192.168.3.6/faucet/'
*/
//后端接口请求地址
  const BASE_GLOB_API = 'https://www.revonft.com/revow';
// const BASE_GLOB_API = 'http://revo-server.v520.top/revow';
// const BASE_GLOB_API = 'http://220.203.230.188:8801/revow';
// const BASE_GLOB_API = 'http://localhost:8081/revow';

//'http://220.203.230.188:8081/revow';
const BASE_GLOB_DOMAIN = 'https://www.revonft.com';
// const BASE_GLOB_DOMAIN = 'http://revo-server.v520.top';
// const BASE_GLOB_DOMAIN = 'http://220.203.230.188:8801';
// const BASE_GLOB_DOMAIN = 'http://localhost:8081';

//白皮书地址
const Whitepaper='https://www.revonft.com/book/RevolutionwhitepaperEnglish.pdf'
//doc
const doc='https://revonft.gitbook.io/'
//表单
const formData="https://forms.gle/uYTAKKWgrZNJzkKx6"
//以太坊查看 携带hash
const etherscan="https://etherscan.io/tx/"

const install='https://www.revonft.com/app/Wallet-release.apk'

// 铸造 价格
const mintPrice="0.01"
// 游戏时长
const countDownTime=86400; // 单位 秒(s)
// 投票价格
const votePrice="0.05"
export  {
    BASE_GLOB_API,
    BASE_V_API,
    ContractUrl,
    rtvtokenAddress,
    Rtv721Address,
    marketAddress,
    FaucetAddress,
    Whitepaper,
    doc,
    formData,
    etherscan,
    install,
    mintPrice,
    countDownTime,
    votePrice
}