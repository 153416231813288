// 可自行拆分，这里懒得分模块了
export const lang = {
    //首页
    home: 'ホームページ',
    opting: 'ゲーム',
    market: 'トレーディングマーケット',
    mine: 'マイ',
    whitePaper:"doc",
    selling: '販売中',
    coming: "まもなく販売が始まります",
    days: "日",
    houres: "時",
    minutes: "分",
    seconds: "秒",
    PubilcSale: "サブスクライブへ",
    genesis: "ジェネシスNFTの発行",
    genesisdesc: "ETHが結びついたユニークなNFTを購入して鋳造し、あなたに大きな利益をもたらす可能性のある新しい旅を始めましょう",
    viewAll: 'すべて表示',
    gameNavigation: "ゲーム大航海",
    refresh: "グループを変更する",
    firstNFT: "最初のNFT製品",
    cooperation: "- 協調的・非協調的なゲーム・プライシング・システムとバリュー・シンク・モデルの活用",
    Popular: "人気のある取引市場",
    //上传
    UploadFile: "画像をアップロード",
    ChooseFile: "画像を選択",
    maxFile: "PNG、GIF、JPG 最大20Ｍ",
    Title: "タイトル",
    TitlePlaceholder:"タイトルを入力してください",
    Description: "説明",
    DescPlaceholder:"説明を入力してください",
    mint: "ミント",
    //OPTING
    SortedBy: "並び替え",
    value: "価値",
    numberOfpart: '参加人数',
    Difference: "人数差",
    follow: "フォロワー",
    Countdown: "間もなく終了",
    //MARKET
    results: '件の結果',
    price: '価格',
    Time: "時間",
    highTolow: "高い順から",
    lowTohigh: "低い順から",
    // detail
    Recent: '最近',
    SalesHistory: "取引履歴",
    HistoryOfParticipation: "過去の参加者数",
    publish: "出版",
    sell: "売る",
    //购买弹窗
    saleDetails: "販売詳細",
    flxedPrice: "固定価格",
    soldPrice: "固定価格で販売",
    postListing: "ポストリスティング",
    //取消挂单
    cancel: '保留中の注文をキャンセルする',
    cancelDesc: "保留中の注文をキャンセルすると、マーケットリストに表示されなくなる",
    think: "考えさせて",
    cacelListing: "キャンセルリスト",
    sellPrice: "価格",
    endTime: "終了時間",
    gameState: "ゲーム状態",
    ticketPrice: "チケット",
    Allocation: "ボーナス配布",
    settle: "決済",
    waiting: "決済待ち",
    Settled:"解決",
    Join: "今すぐ参加する",
    chooseCamp: "あなたの陣営を選びます",
    Confirm: "確認",
    earnings: "獲得",
    info: '同点の場合、参加者はチケット価格の5％が差し引かれます',
    //mine
    account: "マイアカウント",
    Portfolio: "資産ポートフォリオ",
    Participating: "使用中の資金",
    Available: "持ち前の資金",
    withdraw: "引き出し",
    myNFT: "マイNFT",
    participation: "履歴",
    Purchase: "買い取り",
    buy: "",
    buyInfo: "で買う",
    People: "人数",
    AddCollect: "コレクションを追加",
    CancelCollect: "コレクションをキャンセル",
    nftLevelUp: "NFTのレベルアップ",
    fee: "手数料 (5%)",
    recieved: "受け取る金額",
    transactionFailed: "取引失敗",
    failedDesc: "ユーザーがトランザクション署名をキャンセルしました",
    minting: "鋳造中",
    successTrasaction: "決済完了後、「My NFT」ページで確認してください",
    etherscan: "イーサリアムブラウザで表示",
    confirmation: "確認待ち",
    or: "または",
    votingFailed: "投票に敗れる",
    votingSuccess: "選択成功",
    votingSuccessLater: "後でページを更新する",
    failed: "失敗",
    //sale
    PurchaseType:"サブスクリプション",
    endingTime: "締め時間",
    token: "トークン",
    tokensale: "総供給量",
    Contract: "契約住所",
    Personal: "個人明細",
    TotalBalance: "購入数量",
    Locked: "ロック数",
    Claim: "交換",
    round:"第",
    roundDesc:"ラウンド",
    Fundraise:"募集金額",
    Amount:"トークンの供給量",
    Price:"交換比率",
    LockUp:"ロック状況",
    lockUpDesc:"プロジェクト稼働率0％、以降70日ごと（最初の70日は20％、2番目の70日は30％、3番目の70日は50％）",
    receive:"あなたは獲得します",
    Balance:"総額",
    Buy:"サブスクリプション",
    distribution:"公募ラウンドの詳細",
    Tokens:"供給量",
    Prices:"交換比率",
    Supply:"パーセンテージ",
    Raise:"募集金額",
    detailed:"トークンエコノミーの詳細",
    TotalSupply:"総供給量",
    desc1:"Revolution（rtv）は、Etherのように分散型および集中型の取引所で取引可能なerc20準拠のトークンです。",
    desc2:"プレイヤーや投資家にとっては、プラットフォームへの通過点であるだけでなく、独立した価値ある通貨でもある",
    Activities:"取引履歴",
    Date:"日付",
    prices:"価格",
    Priced:"価格",
    foundation:"財団",
    maketing:"マーケティング",
    team:"チーム",
    publicSale:"公募ラウンド",
    yieldFarming:"マイニング",
    please:"ちょっと待ってください",
    MateMask:"まずMateMaskをインストールしてください。アカウントをバインドします",
    withdrawSuccess:"現金引き出し成功",
    copy:"クリップボードにコピーしました",
    copyError:"コピーに失敗しました",
    PurchaseSuccess:'購入成功',
    fileTypeError:"アップロードのファイルタイプが間違っています",
    allocationInfoLine1:'同点の場合、参加者はチケット価格の5％が差し引かれます',
    allocationInfoLine2:'',
    WhatweDo:'我々ができること',
    realized:'価値を実現し、流通を簡単に',
    //2022-09-09 任务奖励
    taskDetail:'タスク詳細',
    reward:'インタラクティブ報酬',
    oneTime:'ワンタイム',
    completed:'ミント、パブリッシュ、発売のタスクがすべて完了すると、インタラクティブな報酬を得ることができます。',
    Reward:'報酬',
    dailyReward:'日次報酬',
    NumberVotes:'票数',
    Daily:'毎日',
    taskReset:'30票ごとに1RTVトークンを獲得し、1日獲得できるトークンの上限は3RTVとする。 タスクは毎日リセットされます',
    votes200:'得票数200件達成',
    Eligible:'対象',
    addresses:'アドレス',
    closeTask500:"テスト期間中、対象アドレスが500件に達した時点で、タスクを終了します",
    RankingReward:'ランキング報酬',
    vote:'票数',
    ongoing:'進行中',
    end:'終了',
    voteObtained:'得票数',
    award:'最終的な賞は、最終的な順位に従うものとする。',
    bug:'バグ/提案の報酬',
    contactUs:'お問い合わせください。',
    // airdrop
    Airdrop:'エアドロップ',
    Tasks:'タスク',
    Ranking:'ランキング',
    ClaimRTV:'RTVトークンを交換す',
    ConnectWallet:'ウォレットに接続',
    Faucet:'フォーセット',
    Whitepaper:'ホワイトペーパー',
    create:'クリエイト',
    introduced:"NFTをより本格的にゲーム化できるゲーミング仕組みを導入し、NFTにおける価値発見を可能にします。",
    auctionModel:'従来のシングルオークションモデルに比べ、協力・非協力ゲームモデルを導入し、NFTに充実したゲームを与え、価格をNFT自体の価値に近づけることで、NFTの価値発見を可能にしました。',
    Towinnners:'優勝者',
    ToNFTLevelUp:'NFTランクアップ',
    VotesObtained:'得票数',
    rankingNumber:'ランク',
    quantity:'番号',
    Address:'住所',
    AttentionPlease:'注意',
    AttentionText:'テスト中に取引や投票によって獲得したトークン（ETHとRTV）は、すべてテストネットワークのためのテストコインです タスクをクリアして獲得したRTVトークンのみ、イーサリアムメインネットで交換可能です',
    AttentionText2:'',
    TotalVoting:'総得票数',
    TotalBoundValue:"トータルバウンド額",
    TotalSalesValue:"売上高合計",
    TotalEarnings:"総収益",
    OpenPositions:'ポジションオープン',
    Cash:"キャッシュ",
    Withdraw:"引出す",
    InteractiveReward:"インタラクティブな報奨金",
    DailyReward:'毎日の報奨金',
    bugReward:"バグ/提案の報奨",
    RankingReward:"ランキング報酬",
    viewRanking:"ランキングを見る",
    pictureRule:"私は、プラットフォームの画像ランキングルールを読み、同意します。",
    CompleteYourListing:"掲載を完了する",
    ApproveNFT:"NFTを承認する",
    ConfirmListing:'掲載確認',
    AllOption:"すべて",
    Active:"アクティブ",
    Closed:"クローズド",
    EndSoon:"まもなく終了",
    Liked:"お気に入り",
    Volume:"票数",
    HighToLow:"高得点から低得点",
    LowToHigh:"少ない方から多い方へ",
    BoundValue:"バインディング値",
    DifferenceVotes:"得票数の変化",
    Recently:"最近掲載されたもの",
    Boundvalue:"バインド値",
    WaitingSettlement:"決済を待つ",
    Finished:"終える",
    Filter:"濾過",
    uploadSuccess:"アップロードが成功した",
    immediately:'審査・承認後、すぐにMy NFTにキャスト',
    Mint:"キャスティング",
    Filter:"フィルター",
    COMPLETE:"支払い完了",
    ConfirmCheckOut:"支払い確認",
    
    username: 'アカウント',
    email: '電子メール',
    verifyCode: 'キャプチャ',
    sendVerifyCode:'確認コードを送信する',
    password: 'パスワード',
    confirmPassword: 'パスワードの確認',
    createAccount:'アカウントを作成',
    usernamePlaceholder: 'アカウント名を入力してください',
    emailPlaceholder: '認証コードを入力してください',
    verifyCodePlaceholder: '認証コードを入力してください',
    passwordPlaceholder: 'パスワードを入力してください',
    confirmPasswordPlaceholder: 'パスワードを再入力してください',
    usernameRule: 'アカウントを空にすることはできません',
    //需要翻译的
    Dashboard:'ダッシュボード',
    estimated:'推定残高',
    wallet:'ウォレット概要',
    funding:'資金調達概要',
    deposit:'預金',
    withdraw:'引き出し',
    crypto:' 暗号資産を預ける',
    coin:'コインを選択',
    network:'ネットワークを選択',
    address:'預金アドレス',
    pleaseCoin:'コインを選択してください',
    pleaseNetwork:' ネットワークを選択してください',
    textNetwork:'ネットワーク',
    cancel:' キャンセル',
    recharged:'リチャージ済み',
    submit:'提出する',
    minimum:'最小預金',
    replicationSuccessful:'複製に成功しました',
    replicationFailed:'複製に失敗しました',
    received:'受け取りました',
    notRreceived:'受け取っていない',
    withdrawCrypto:'暗号資産を引き出す',
    SendAddress:'アドレスに送る',
    Amount:'量',
    Balance:'残高',
    minimunWithdrawal:'最小引き出し額',
    All:'すべての',
    premium:'プレミアム',
    rate:'レート',
    amountReceived:'받은 금액',
    gas:'가스비',
    amountReceived:'受取金額',
    gas:'ガス代',
    textRevolution:'プラットフォームでサポートされているネットワークのみ表示されることに注意してください。別のネットワーク経由で預け入れた場合、資産が失われる可能性があります。',
    Security:'セキュリティ',
    Authentication:'二要素認証',
    Email:'メール',
    account:'アカウントと取引を保護するためにメールを使用してください。',
    LoginPassword:'ログインパスワード',
    textPassword:'ログインパスワードは、アカウントにログインするために使用されます。',
    Modify:'メール変更',
    textVerifyCode:'検証コードを入力してください',
    textEmailAddress:' 新しいメールアドレスを入力してください',
    loginModify:'ログインパスワード変更',
    ForgotPassword:'パスワードを忘れた',
    oldPassword:'旧パスワードを入力してください',
    newPassword:'新しいパスワードを入力してください',
    againPassword:'もう一度新しいパスワードを入力してください',
    PasswordText:'新しいパスワードが再入力した新しいパスワードと一致しません',
    PasswordModify:'パスワード変更',
    VerifyCode:'検証コード',
    NewEmail:'新しいメール',
    OldPassword:'旧パスワード',
    NewPassword:'新パスワード',
    ConfirmNewPassword:'新パスワード（再入力）',
    Fund:'資金',
    alls:'すべて',
    available:'利用可能',
    Frozen:'凍結',
    other:'その他',
    Transaction:'取引',
    Identification:'身分証明',
    Payment:'支払い',
    logout:'ログアウト',
    Login:'ログイン',
    Welcome:'RevoNFTへようこそ。',
    Register:' 体験するために会員登録してください。',
    EmailName:'メール/アカウント名',
    PleaseEmailName:' メール/アカウント名を入力してください',
    SignUp:'サインアップ',
    NeedAccount:'エンティティアカウントが必要ですか？',
    AlreadyAccount:'すでにアカウントをお持ちですか？',
    verification:'メールに認証コードが送信されました',
    receive:'認証コードが届きませんか？',
    Resend:'再送信',
    addressEmpty:'アドレスは空にできません',
    withdrawalEmpty:'引き出し額を空にできません',
    platformEmpty:'プラットフォームIDは空にできません',
    transactionType:'取引の種類',
    Payouts:'支払い',
    Into:'入金',
    RollOut:' 出金',
    TransactionID:'取引ID：',
    PersonalCenter:'プロファイル',
    ReturnPage:'戻る',
    currency:'通貨',
    EditProfilePhoto:'プロファイル写真を編集',
    Profile:'プロファイル',
    Referral:'紹介コード',
    ViewInvitationCode:'招待コードを見る',
    referral:'紹介コードを入力してください',
    QRCodeInvitations:'QRコード招待',
    ScanTheQRCode:'QRコードをスキャン',
    invitations:'リンク招待',
    invitedUsers:'招待リンクをコピーして、招待されたユーザーに送信してください',
    registerNumber:'登録に招待された人数',
    PersonalInformation:'個人情報',
    LegalName:'法的名前',
    PleaseLegalName:'法的名前を入力してください',
    DateOfBirth:'生年月日',
    PleaseDateOfBirth:'生年月日を入力してください',
    ideAddress:'住所',
    PleaseIdeAddress:'住所を入力してください',
    IdentificationNumber:'身分証明番号',
    PleaseIdentificationNumber:'身分証明番号を入力してください',
    IdentificationDocuments:'身分証明書',
    frontIDCard:'身分証明書の表面',
    frontIDCardText:'身分証明書の表面をアップロードしてください',
    ReverseIDCard:'身分証明書の裏面',
    ReverseIDCardText:' 身分証明書の裏面をアップロードしてください',
    EmailAddress:'メールアドレス',
    Manage:'取り締まる',
    ResetPassword:'パスワードをリセットする',
    Unauthorized:'未承認',
    authorized:'承認済み',
    confirmText:'確認',
    cancelText	:'キャンセル',
    authentication	:'実名認証',
    UnderReview	:'審査中',
    Failed	:'失敗',
    PasswordVerification:'パスワード検証',
    PleasePassword:'パスワードを入力してください',
    typeTransaction:'種類',
    amountTransaction:'量',
    Authentication:'二要素認証',
    enable:'この機能を有効にしてもよろしいですか?',
    OFF:'閉める',
    ON:'開ける',
    Enable:'エネーブル',
    RemoveMyNFT:'「マイNFT」から削除します。',
    AreYouSure:'確かですか',
    ToRemove:'削除するには?',
    feedback:'ユーザーフィードバック',
    currencyType:'通貨の種類',
    amount:'量',
    Time:'時間',
    ReviewStatus:'審査状況',
    ReasonReview:'審査理由',
    WithdrawalHistory:'退会履歴',
    announcement:'告知',
    Ongoing:'進行 中',
    Ended:'終わった',
    NumberParticipants:'参加人数',
    EndTime:'終了時刻',
    totalAmount:'合計金額',
    GameID:'ゲームID',
    gasDeduction:'ガス代は実際の控除の対象となります',
    GameTitle:'ゲームタイトル',
    feedbackType:'フィードバックの種類',
    FeedbackContent:'フィードバック内容',
    LockUp:'ロックアップ',
    Destroyed:'滅びる',
    arrived:'届いたかどうか',
    ReviewTime:'レビュー時間',
    ProcessingStatus:'処理ステータス',
    ReasonRejection:'却下理由',
    Delete:'削除',
    SignUp:'サインアップしてログイン',
    Sale:'販売',
    Game:'ゲーム',
    bill:'請求書',
    Deposits:'入出金',
    CreationTime:'作成時刻',
    Read:'読む',
    Unread:'未 読',
    teamwork:'チームワーク',
    Referral:'紹介報酬',
    community:'コミュニティリワード',
    SuperNode:'スーパーノード報酬',
    downlines:'総人数',
    Completed:'完了したセッション',
    Sessions:'セッションを完了する必要があります',
    source:'源',
    Expected:'期待される効果',
    install:'Revoのインストール',
    TotalRevenue:'チームの収益',
    contributors:'コントリビューターの数',
    settled:'決済済みかどうか',
    sed:'解決',
    unSed:'未決済',
}