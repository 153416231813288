// 可自行拆分，这里懒得分模块了
export const lang = {
    //首页
    home: 'Home',
    opting: 'Opting',
    market: 'Market',
    mine: 'Mine',
    whitePaper:"Doc",
    selling:'Public-sale is selling',
    coming:"Public-sale is coming",
    days:"DAYS",
    houres:"HOURES",
    minutes:"MINUTES",
    seconds:"SECONDS",
    PubilcSale:"Pubilc-sale",
    genesis:"Genesis NFT Published",
    genesisdesc:"Purchase and mint a unique NFT for you which binds eth token ，and then start a journey that bring you benefits",
    viewAll:'View all',
    gameNavigation:'Game Navigation',
    refresh:"Refresh",
    firstNFT:"The first NFT Product",
    cooperation:"using cooperative and non-cooperative game pricing systems and value precipitation models",
    Popular:"The Most Popular Trading Market",
    //上传
    UploadFile:"Upload file",
    ChooseFile:"Choose File",
    maxFile:"PNG、GIF、JPG Max 20MB",
    Title:"Title",
    TitlePlaceholder:"Please input title",
    Description:"Description",
    DescPlaceholder:"Please input Description",
    mint:"Upload",
    //OPTING
    SortedBy:"Sorted by",
    value:"Value",
    numberOfpart:'Number of participants',
    Difference:"Difference",
    follow:"Follow",
    Countdown:"Countdown",
    //MARKET
    results:'results',
    price:'price',
    Time:"Time",
    highTolow:"high to low",
    lowTohigh:"low to high",
    // detail
    Recent:'Recent',
    SalesHistory:"Sales history",
    HistoryOfParticipation:"History of participation",
    publish:"Publish",
    sell:"Sell",
    //购买弹窗
    saleDetails:"SALE DETAILS",
    flxedPrice:"Flxed Price",
    soldPrice:"It will be sold at the price you set",
    postListing:"POST LISTING",
    //取消挂单
    cancel:'CANCEL',
    cancelDesc:"Cancellation will not be displayed in the list of trading markets",
    think:"LET ME THINK",
    cacelListing:"CANCEL LISTING",
    sellPrice:"Sell Price",
    endTime:"End time",
    gameState:"Game state",
    ticketPrice:"Ticket price",
    Allocation:"Allocation",
    settle:"Settle",
    waiting:"Waiting for settlement",
    Settled:"Settled",
    Join:'Join now',
    chooseCamp:"CHOOSE YOUR CAMP",
    Confirm:"CONFIRM",
    earnings:"Your earnings",
    info:'In the event of a tie, participants will be deducted 5% of  the ticket price, which means a return of 95%',
    //mine
    account:"My account",
    Portfolio:"Balance",
    Participating:"Participating",
    Available:"Available",
    withdraw:"withdraw",
    Withdrawal:"Withdrawal",
    myNFT:"My NFT",
    participation:"History of participation",
    Purchase:"Purchase",
    buy:"Buy for",
    buyInfo:"",
    People:"People",
    AddCollect:"Add Collect",
    CancelCollect:"Cancel Collect",
    nftLevelUp:"To NFT Level-Up",
    fee:"Fee(5%)",
    recieved:"Recieved",
    transactionFailed:"TRANSACTION FAILED",
    failedDesc:"User denied transaction signature",
    minting:"MINTING",
    successTrasaction:"check 'MY NFT' after trasaction success",
    etherscan:"view transaction on etherscan",
    confirmation:"Waiting for confirmation",
    or:"OR",
    votingFailed:"VOTING FAILED",
    votingSuccess:"OPTING COMPLETE",
    votingSuccessLater:"Refresh website few minutes later",
    failed:"FAILED",
    //sale
    PurchaseType:"Purchase",
    endingTime:"ENDING TIME",
    token:"Token",
    tokensale:"Total sale",
    Contract:"Contract",
    Personal:"Personal",
    TotalBalance:"Total Balance",
    Locked:"Locked",
    Claim:"Claim",
    round:"ROUND",
    roundDesc:"",
    Fundraise:"Fundraise",
    Amount:"Amount",
    Price:"Price",
    LockUp:"Lock-up",
    lockUpDesc:"0% unlocked at the TGE, release every 70 days(1st-70Days，20%；2nd-70Days，30%；3rd-70Days，50%；)",
    receive:"You will receive",
    Balance:"Balance",
    Buy:"Buy",
    distribution:"Public round distribution",
    Tokens:"Tokens",
    Prices:"Price",
    Supply:"Supply",
    Raise:"Raise",
    detailed:"DETAILED TOKEN METRICS",
    TotalSupply:"Total token supply",
    desc1:"Revolution(rtv) is an encrypted token conforming to erc20 standard,which can be traded on dex and cex like Ethereum",
    desc2:"For players and investors,it is not only a game entry platform currency,but also an independent value currency",
    Activities:"Activities",
    Date:"Date",
    prices:"price",
    Priced:"Price",
    //饼图
    foundation:"foundation",
    maketing:"maketing",
    team:"team",
    publicSale:"public sale",
    yieldFarming:"yield farming",
    please:"One moment please",
    MateMask:"Please install MateMask first. Bind account",
    withdrawSuccess:"withdraw success",
    copy:"Copied to clipboard",
    copyError:"Sorry, copy failed",
    PurchaseSuccess:'Purchase successful',
    fileTypeError:"The uploaded file type is incorrect",
    allocationInfoLine1:'In the event of a tie, participants will be deducted 5% ',
    allocationInfoLine2:'of the ticket price, which means a return of 95%',
    WhatweDo:'What we do',
    realized:'Let the value be realized ，let the circulation be simplicity',
    //2022-09-09 任务奖励
    taskDetail:'Task detail',
    reward:'Interactive reward',
    oneTime:'One-time',
    completed:'You will get interactive reward when tasks（mint/publish/sell）are all completed.',
    Reward:'Reward',
    dailyReward:'Daily reward',
    NumberVotes:'number of votes',
    Daily:'Daily',
    taskReset:'1 RTV per 30-votes ，no more than 3 RTV everyday.The mission will be reset daily！',
    votes200:'Vote-obtained reach 200',
    Eligible:'Eligible',
    addresses:'addresses',
    closeTask500:"During the test , When the number of eligible address reach 500 , we'll close the mission",
    RankingReward:'Ranking Reward',
    vote:'vote',
    ongoing:'ongoing',
    end:'end',
    voteObtained:'vote-obtained',
    award:'The final award shall be subject to the final ranking',
    bug:'BUG/Suggestions reward',
    contactUs:'contact us',
    // airdrop
    Airdrop:'Airdrop',
    Tasks:'Tasks',
    Ranking:'Ranking',
    ClaimRTV:'Claim $RTV',
    ConnectWallet:'Connect wallet',
    Faucet:'Faucet',
    Whitepaper:'Whitepaper',
    create:'Create',
    introduced:"The game form is introduced to make NFT go through a more sufficient game, so as to realize the value discovery of NFT.",
    auctionModel:'Compared with the traditional single auction model, cooperative game and non cooperative game models are introduced to make NFT have more sufficient game and make the price closer to the value of NFT itself, so as to realize the value discovery of NFT.',
    Towinnners:'To winnners',
    ToNFTLevelUp:'To NFT Level-Up',
    VotesObtained:'Votes obtained',
    rankingNumber:'Ranking',
    quantity:'Quantity',
    Address:'Address',
    AttentionPlease:'Attention please',
    AttentionText:'During the test, all tokens you earned (ETH&RTV) by trading or voting are testcoins on the test network !',
    AttentionText2:'Only the RTV by completing the task can be claimed on Ethereum Mainnet !',
    TotalVoting:'Total Voting',
    TotalBoundValue:"Total Bound Value",
    TotalSalesValue:"Total Sales Value",
    TotalEarnings:"Total Earnings",
    OpenPositions:'Open Positions',
    Cash:"Cash",
    // Withdraw:"Withdraw",
    InteractiveReward:"Interactive reward",
    DailyReward:'Daily reward',
    bugReward:"BUG/Suggestions reward",
    RankingReward:"Ranking reward",
    viewRanking:"View ranking",
    pictureRule:"I have already read and agree the picture ranking rules of the platform",
    CompleteYourListing:"Complete your listing",
    ApproveNFT:"Approve NFT",
    ConfirmListing:'Confirm listing',
    AllOption:"All",
    Active:"Active",
    Closed:"Closed",
    EndSoon:"Ending Soon",
    Liked:"Liked",
    Volume:"Volume",
    HighToLow:"High to Low",
    LowToHigh:"Low to High",
    BoundValue:"Bound Value",
    DifferenceVotes:"Difference in votes",
    Recently:"Recently listed",
    Boundvalue:"Bound-Value",
    WaitingSettlement:"Waiting for settlement",
    Finished:"Finished",
    //
    uploadSuccess:"UPLOADED SUCCESSFULLY",
    immediately:'After passing the audit,you can mint immediately in "MY NFT"',
    Mint:"Mint",
    Filter:"Filter",
    COMPLETE:"COMPLETE CHECKOUT",
    ConfirmCheckOut:"Confirm check out",
    username:'Username',
    email:'Email',
    verifyCode:'Verification Code',
    sendVerifyCode:'Send Verification Code',
    password:'Password',
    confirmPassword:'Confirm password',
    createAccount:'Create Account',
    usernamePlaceholder:'Please enter your username',
    emailPlaceholder:'Please enter your email',
    verifyCodePlaceholder:'Please enter your verification code',
    passwordPlaceholder:'Please enter your password',
    confirmPasswordPlaceholder:'Please enter your password again',
    usernameRule:'Username is required',
    //
    Dashboard:'Dashboard',
    estimated:'Estimated Balance',
    wallet:'Wallet Overview',
    funding:'Funding Overview',
    deposit:'Deposit',
    withdraw:'Withdraw',
    crypto:'Deposit Crypto',
    coin:'Select Coin',
    network:'Select Network',
    address:'Deposit Address',
    pleaseCoin:'Please Select Coin',
    pleaseNetwork:'Please Select Network',
    textNetwork:'Network',
    cancel:'Cancel',
    recharged:'Recharged',
    submit:'submit',
    minimum:'Minimum deposit',
    replicationSuccessful:'The replication is successful',
    replicationFailed:'The replication is failed',
    received:'Received',
    notRreceived:'Not received',
    withdrawCrypto:'Withdraw Crypto',
    SendAddress:'Send to Address',
    Amount:'Amount',
    Balance:'Balance',
    minimunWithdrawal:'Minimun withdrawal',
    All:'All',
    premium:'premium',
    rate:'rate',
    amountReceived:'The amount received',
    gas:'Gas fees',
    textRevolution:'Please note that only supported networks on Revolution platform are shown. If you deposit via another network your assets may be lost.',
    Security:'Security',
    Authentication:'Two-Factor Authentication',
    Email:'Email',
    account:'Use your email to protect your account and transactions.',
    LoginPassword:'Login Password',
    textPassword:'Login password is used to log in to your account.',
    Modify:'Mail Modify',
    textVerifyCode:'Please enter verifyCode',
    textEmailAddress:'Please enter new email address',
    loginModify:'Login Password Modify',
    ForgotPassword:'Forgot password',
    oldPassword:'Please enter oldPassword',
    newPassword:'Please enter new password',
    againPassword:'Please enter new password again',
    PasswordText:'The new password does not match the new password again',
    PasswordModify:'Password Modify',
    VerifyCode:'VerifyCode',
    NewEmail:'New Email',
    OldPassword:'Old Password',
    NewPassword:'New Password',
    ConfirmNewPassword:'New password again',
    Fund:'Fund',
    alls:'all',
    available:'available',
    Frozen:'Frozen',
    other:'other',
    Transaction:'Account Flow',
    Identification:'Identification',
    Payment:'Payment',
    logout:'logout',
    Login:'Login',
    Welcome:'Welcome to RevoNFT.',
    Register:'Register as a member to experience.',
    EmailName:'Email/Account Name',
    PleaseEmailName:'Please enter your Email/Account Name',
    SignUp:'Sign Up',
    NeedAccount:'Need an entity account?',
    AlreadyAccount:'Already have an account?',
    verification:'A verification code has been sent to email',
    receive:'Did not receive the verification code?',
    Resend:'Resend',
    Resend:'Resend',
    addressEmpty:'The address cannot be empty',
    withdrawalEmpty:'The withdrawal amount cannot be empty',
    platformEmpty:'The platform ID cannot be empty',
    transactionType:'The type of transaction',
    Payouts:'Payouts',
    Into:'Into',
    RollOut:'Roll out',
    TransactionID:'Transaction',
    PersonalCenter:'Profile',
    ReturnPage:'Return',
    currency:'currency',
    EditProfilePhoto:'Edit Profile Photo',
    Profile:'Profile',
    Referral:'Referral Code',
    ViewInvitationCode:'View Invitation Code',
    referral:'Please enter the referral code',
    QRCodeInvitations:'QR code invitations',
    ScanTheQRCode:'Scan the QR code',
    invitations:'Link invitations',
    invitedUsers:'Copy the invitation link and send it to the invited users',
    registerNumber:'The number of people who have been invited to register',
    PersonalInformation:'Personal information',
    LegalName:'Legal Name',
    PleaseLegalName:'Please enter Legal Name',
    DateOfBirth:'Date of Birth',
    PleaseDateOfBirth:'Please enter Date of Birth',
    ideAddress:'Address',
    PleaseIdeAddress:'Please enter ideAddress',
    IdentificationNumber:'Identification Number',
    PleaseIdentificationNumber:'Please enter Identification Number',
    IdentificationDocuments:'Identification Documents',
    frontIDCard:'The front of the ID card',
    frontIDCardText:'Please upload the front of your ID card',
    ReverseIDCard:'Reverse side of ID card',
    ReverseIDCardText:'Please upload the back of your ID card',
    EmailAddress:'Email Address',
    Manage:'Manage',
    ResetPassword:'Reset your password',
    Unauthorized:'Unauthorized',
    authorized:'authorized',
    confirmText:'confirm',
    cancelText	:'cancel',
    authentication	:'Real-name authentication',
    UnderReview	:'Under Review',
    Failed	:'Failed',
    PasswordVerification:'Password verification',
    PleasePassword:'Please enter your password',
    typeTransaction:'type',
    amountTransaction:'amount',
    Authentication:'Two-Factor Authentication',
    enable:'Are you sure to enable this?',
    OFF:'OFF',
    ON:'ON',
    Enable:'Enable',
    RemoveMyNFT:'Remove from “MY NFT”',
    AreYouSure:'ARE YOU SURE',
    ToRemove:'TO REMOVE?',
    feedback:'User feedback',
    currencyType:'Currency type',
    amount:'amount',
    Time:'Time',
    ReviewStatus:'review status',
    ReasonReview:'reason for review',
    WithdrawalHistory:'withdrawal history',
    announcement:'announcement',
    Ongoing:'Ongoing',
    Ended:'Ended',
    NumberParticipants:'Number of participants',
    EndTime:'End time',
    totalAmount:'The total amount',
    GameID:'Game ID',
    gasDeduction:'The gas fee is subject to the actual deduction',
    GameTitle:'Game title',
    feedbackType:'The type of feedback',
    FeedbackContent:'Feedback content',
    LockUp:'Lock-up',
    Destroyed:'Destroyed',
    arrived:'Whether it has arrived',
    ReviewTime:'Review time',
    ProcessingStatus:'Processing status',
    ReasonRejection:'Reason for rejection',
    Delete:'Delete',
    SignUp:'Sign up and log in',
    Sale:'Sale',
    Game:'Game',
    bill:'bill',
    Deposits:'Deposits and withdrawals',
    CreationTime:'Creation time',
    Read:'Read',
    Unread:'Unread',
    teamwork:'Teamwork',
    Referral:'Referral reward',
    community:'community rewards',
    SuperNode:'Super node rewards',
    downlines:'Total number of people',
    Completed:'Completed sessions',
    Sessions:'Sessions need to be completed',
    source:'source',
    Expected:'Expected Benefits',
    install:'install Revo',
    TotalRevenue:'Team earnings',
    contributors:'Number of contributors',
    settled:'Whether it has been settled',
    sed:'Settled',
    unSed:'Not settled',
}