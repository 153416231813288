import axios from 'axios'
import qs from 'qs'
import store from '../store'
import Mixin from '@/mixins/index.js';
import {isPc} from '@/utils/index';
// http request 拦截器
console.log(Mixin)
axios.interceptors.request.use(
	(config) => {
		// alert(3)
		// console.log(config, 'config')
		// 设置请求头中的appid参数
		// config.headers['Content-Type'] = 'application/json;charset=utf-8'
		// config.headers['request_header_user_id'] = 1
		let token = window.localStorage.getItem('Token')
		let lang = sessionStorage.getItem("Language")
		if (token) {
			config.headers.Authorization = "Bearer " + token
			config.headers['X-Access-Token'] = token;
			config.headers['token'] = token;
			if(lang == 'en'){
				config.headers['custom-lang'] = 'en-US,en;q=0.9';
			}else if(lang == 'cn'){
			    config.headers['custom-lang'] = 'zh-TW,zh;q=0.9';
			}else if(lang == 'ja'){
			    config.headers['custom-lang'] = 'ja-JP,ja;q=0.9';
			}else if(lang == 'ko'){
			    config.headers['custom-lang'] = 'ko-KR,ko;q=0.9';
			}else{
				config.headers['custom-lang'] = 'en-US,en;q=0.9';
			}
			
		}else{
			
			if(lang == 'en'){
				config.headers['custom-lang'] = 'en-US,en;q=0.9';
			}else if(lang == 'cn'){
			    config.headers['custom-lang'] = 'zh-TW,zh;q=0.9';
			}else if(lang == 'ja'){
			    config.headers['custom-lang'] = 'ja-JP,ja;q=0.9';
			}else if(lang == 'ko'){
			    config.headers['custom-lang'] = 'ko-KR,ko;q=0.9';
			}else{
				config.headers['custom-lang'] = 'en-US,en;q=0.9';
			}
		}
		if (config.data instanceof window.FormData) {
			// console.log('{Content-Type: application/x-www-form-urlencoded}')
		} else {
			if (config.method === 'post' || config.method === 'put') {
				config.headers['Content-Type'] = 'application/json;charset=utf-8'
			}
			if (config.method === 'get') {
				config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
				config.data = qs.stringify(config.data)
			}
			if (config.method === 'delete') {
				config.headers['Content-Type'] = 'application/json;charset=utf-8'
			}
		}
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)

// http response 拦截器
axios.interceptors.response.use(
	(response) => {
		console.log("interceptors response:", response);
		return response
	},
	(error) => {
		if (error.response) {
			switch (error.response.status) {
				case 401:
					localStorage.removeItem("Token");
					// 401 清除token信息并跳转到登录页面
					// store.commit(types.SET_USER_LOGOUT)
					console.log("isPc:", isPc())
					if(isPc()){
						window.vm.$router.push({
							path: '/login',
							query: {
								redirect: window.vm.$router.currentRoute.fullPath
							}
						})
					}else{
						window.vm.$router.push({
							path: '/login-m',
							query: {
								redirect: window.vm.$router.currentRoute.fullPath
							}
						})
					}
					
					break
				case 403:
					// 403 跳转至登录页面（供应商被冻结）
					break
				case 404:
					// 404 跳转至404页面
					break
				case 406:
					// 406 跳转至登录页面（个人账号被冻结）
					break
				case 409:
					// 入驻成功
					break
				case 500:
					// 500 跳转至维修中页面
					// if(isPc()){
					// 	window.vm.$router.push({
					// 		path: '/systemMaintenance',
					// 		query: {
					// 			redirect: window.vm.$router.currentRoute.fullPath
					// 		}
					// 	})
					// }else{
					// 	window.vm.$router.push({
					// 		path: '/systemMaintenance-m',
					// 		query: {
					// 			redirect: window.vm.$router.currentRoute.fullPath
					// 		}
					// 	})
					// }
					break
				default:
					return Promise.reject(error)
			}
			return Promise.reject(error)
		}
	})
export default axios